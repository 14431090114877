<template>
  <div id="MyBlog">
    <div style=" margin: 20px 50px; padding: 10px; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); ">
      <div style="width: 100%; text-align:center; padding: 10px;">
        状态：
        <el-select
          v-model="selectMap.status"
          placeholder="请选择"
          size="mini"
          style="width: 10%; margin: 0px 20px 0px 0px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          size="mini"
          @click="selectMyBlogList()"
          style="margin: 0px 20px"
          >查询</el-button
        >
      </div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="title" label="标题" />
        <el-table-column prop="authorName" label="作者" />
        <el-table-column
          :formatter="formStatus"
          prop="status"
          label="状态"
        />
        <el-table-column
          prop="createTime"
          label="创建日期"
          :formatter="dateFormat"
        />
        <el-table-column label="操作" width="340px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="auditPass(scope.$index, scope.row)"
              v-if="isAdmin && scope.row.status == 2"
            >
              审批通过
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="auditNoPass(scope.$index, scope.row)"
              v-if="isAdmin && scope.row.status == 2"
            >
              审批不通过
            </el-button>
            <el-button
              size="mini"
              @click="publish(scope.$index, scope.row)"
              v-if="scope.row.status == 1"
            >
              发布
            </el-button>
            <el-button
              size="mini"
              @click="withdraw(scope.$index, scope.row)"
              v-if="
                scope.row.status == 2 ||
                  scope.row.status == 4 ||
                  scope.row.status == 3
              "
            >
              撤回
            </el-button>
            <el-button
              size="mini"
              @click="edit(scope.$index, scope.row)"
              v-if="scope.row.status == 1"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="blogDelete(scope.$index, scope.row)"
              v-if="scope.row.status != 4"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.pageSize"
      :current-page="page.pageNum"
      @current-change="handleCurrentChange"
      style="padding: 2px 5px 20px 5px"
    />
  </div>
</template>

<script>
  import moment from "moment";
  import utils from "@/config/config.js";
  export default {
    name: "MyBlog",
    props: {},
    data() {
      return {
        options: [
          {
            value: "0",
            label: "已关闭",
          },
          {
            value: "1",
            label: "已保存",
          },
          {
            value: "2",
            label: "已发布",
          },
          {
            value: "3",
            label: "审批不通过",
          },
          {
            value: "4",
            label: "审批通过",
          },
        ],
        tableData: [],
        parameter: {},
        page: {
          total: 1,
          pageSize: 20,
          pageNum: 1,
        },
        isAdmin: null,
        selectMap: {
          authorName: "",
          status: null,
        },
      };
    },
    mounted() {
      this.selectMyBlogList();
    },
    methods: {
      formStatus(row) {
        switch (row.status) {
          case 0:
            return "已关闭";
          case 1:
            return "已保存";
          case 2:
            return "已发布";
          case 3:
            return "审批不通过";
          case 4:
            return "审批通过";
        }
      },
      handleCurrentChange(val) {
        this.parameter.pageNum = val;
        this.selectMyBlogList();
      },
      /* 日期处理 */
      dateFormat: function(row, column) {
        const date = row[column.property];
        if (date === undefined) {
          return "";
        }
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
      },
      routerPath(pa) {
        if (this.$route.path !== pa) {
          this.$router.push({ path: pa });
        }
      },
      // 审批通过
      auditPass(index, row) {
        const _this = this;
        let url = utils.url + "/api/blog/blogAuditPass/" + row.id;
        $.ajax({
          type: "POST",
          url: url,
          contentType: utils.contentType,
          jsonp: utils.jsonp,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.$message({
                  message: "审批通过",
                  type: "success",
                });
                _this.selectMyBlogList();
              } else {
                _this.$message.error(data.msg);
              }
            } else {
              _this.routerPath("/login");
            }
          },
          error: function(ret) {
            if (ret.status === 403 || ret.status === 401) {
              _this.routerPath("/login");
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
      // 审批不通过
      auditNoPass(index, row) {
        const _this = this;
        let url = utils.url + "/api/blog/blogAuditNoPass/" + row.id;
        $.ajax({
          type: "POST",
          url: url,
          contentType: utils.contentType,
          jsonp: utils.jsonp,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.$message({
                  message: "审批通过",
                  type: "success",
                });
                _this.selectMyBlogList();
              } else {
                _this.$message.error(data.msg);
              }
            } else {
              _this.routerPath("/login");
            }
          },
          error: function(ret) {
            if (ret.status === 403 || ret.status === 401) {
              _this.routerPath("/login");
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
      // 发布
      publish(index, row) {
        const _this = this;
        let url = utils.url + "/api/blog/blogPublish/" + row.id;
        $.ajax({
          type: "POST",
          url: url,
          contentType: utils.contentType,
          jsonp: utils.jsonp,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.$message({
                  message: "发布成功",
                  type: "success",
                });
                _this.selectMyBlogList();
              } else {
                _this.$message.error(data.msg);
              }
            } else {
              _this.routerPath("/login");
            }
          },
          error: function(ret) {
            if (ret.status === 403 || ret.status === 401) {
              _this.routerPath("/login");
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
      // 撤回
      withdraw(index, row) {
        const _this = this;
        let url = utils.url + "/api/blog/blogWithdraw/" + row.id;
        $.ajax({
          type: "POST",
          url: url,
          contentType: utils.contentType,
          jsonp: utils.jsonp,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.$message({
                  message: "撤回成功",
                  type: "success",
                });
                _this.selectMyBlogList();
              } else {
                _this.$message.error(data.msg);
              }
            } else {
              _this.routerPath("/login");
            }
          },
          error: function(ret) {
            if (ret.status === 403 || ret.status === 401) {
              _this.routerPath("/login");
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
      // 编辑
      edit(index, row) {
        this.routerPath("/publish?blogId=" + row.id);
      },
      // 删除
      blogDelete(index, row) {
        const _this = this;
        let url = utils.url + "/api/blog/blogDelete/" + row.id;
        $.ajax({
          type: "DELETE",
          url: url,
          data: JSON.stringify(_this.parameter),
          contentType: utils.contentType,
          jsonp: utils.jsonp,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.selectMyBlogList();
              } else {
                _this.$message.error(data.msg);
              }
            } else {
              _this.routerPath("/login");
            }
          },
          error: function(ret) {
            if (ret.status === 403 || ret.status === 401) {
              _this.routerPath("/login");
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
      selectMyBlogList() {
        this.parameter = {
          authorName: this.selectMap.authorName,
          status: this.selectMap.status,
          pageSize: this.page.pageSize,
          pageNo: this.page.pageNum,
        };
        const _this = this;
        $.ajax({
          type: "GET",
          url: utils.url + "/api/blog/selectMyBlogList",
          data: _this.parameter,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.tableData = data.data.blogPage.records;
                _this.page.total = data.data.blogPage.total;
                _this.isAdmin = data.data.isAdmin;
              } else {
                _this.$message.error(data.msg);
              }
            } else {
              _this.routerPath("/login");
            }
          },
          error: function(ret) {
            if (ret.status === 403 || ret.status === 401) {
              _this.routerPath("/login");
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
    },
  };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#MyBlog{
  width: 100%;
}
</style>
